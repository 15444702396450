import { Card, Statistic } from 'antd';
import { IComponentProperty, ComponentPropertyType } from './../ComponentProperty';
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { Link } from 'react-router-dom';
import useDataSource from '../DataSource';

export const PSUStatisticProps: Array<IComponentProperty> = [
    { "name": "id", "type": ComponentPropertyType.String, "required": false, "description": "The ID for this statistic", displayName: "Id" },
    { "name": "title", "type": ComponentPropertyType.String, "required": true, "description": "The title of this statistic.", displayName: "Title" },
    { "name": "dataSource", "type": ComponentPropertyType.DataSource, "required": true, "description": <>The <Link to="/admin/automation/scripts" target="_blank">script</Link> or <Link to="/admin/apis/endpoints" target="_blank">API</Link> data source for this statistic.</>, displayName: "Data Source", category: "Data Source" },
    { "name": "prefix", "type": ComponentPropertyType.String, "required": false, "description": "Text to display before the value.", displayName: "Prefix" },
    { "name": "suffix", "type": ComponentPropertyType.String, "required": false, "description": "Text to display after the value.", displayName: "Suffix" },
    { "name": "precision", "type": ComponentPropertyType.Number, "required": false, "description": "Number of decimal points to round to.", displayName: "Precision" },
    { "name": "autoReload", "type": ComponentPropertyType.Boolean, "required": false, "description": "Whether this component auto-reloads.", displayName: "Auto Reload" },
    { "name": "autoReloadSeconds", "type": ComponentPropertyType.Number, "required": false, "description": "Number of seconds between auto-reloads.", displayName: "Auto Reload Seconds" },
]

export const NewPSUStatistic = () => {
    return {
        "$type": 'PowerShellUniversal.Components.Statistic, PowerShellUniversal',
        "title": "Statistic",
        "type": "Statistic",
        "id": uuidv4(),
        "autoRefreshSeconds": 5
    }
}

export default function PSUStatistic(props) {
    const { data, isLoading } = useDataSource(props);

    return <Card>
        <Statistic
            title={props.title}
            value={data && data[0]}
            precision={props.precision}
            prefix={props.prefix}
            suffix={props.suffix}
            loading={isLoading}
        />
    </Card>
}