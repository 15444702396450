import React from "react";
import { Menu, Layout } from "antd";
import { useNavigate } from "react-router";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { SiderTheme } from "antd/es/layout/Sider";
import * as Icons from '@ant-design/icons';
import {
  FileTextOutlined,
  CodeOutlined,
  HistoryOutlined,
  ClockCircleOutlined,
  ControlOutlined,
  SettingOutlined,
  LineChartOutlined,
  ApiOutlined,
  FileProtectOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  FolderOutlined,
  GlobalOutlined,
  FunnelPlotOutlined,
  RocketOutlined,
  TagsOutlined,
  SafetyOutlined,
  TeamOutlined,
  IdcardOutlined,
  BarcodeOutlined,
  SecurityScanOutlined,
  BuildOutlined,
  DesktopOutlined,
  GroupOutlined,
  BlockOutlined,
  LoginOutlined,
  HomeOutlined,
  PullRequestOutlined,
  ImportOutlined,
  BugOutlined,
  AppstoreOutlined
} from "@ant-design/icons";
import useAppStore from "components/context/app/Hooks";
import Scrollbars from "rc-scrollbars";
import { useQuery } from "react-query3";
import { Page } from "types";

export default function UAMenu() {
  const navigate = useNavigate();
  const { data: pages, isLoading } = useQuery<Page[]>("/page/view");
  const { currentTheme } = useThemeSwitcher();
  const { settings, userData } = useAppStore();

  function onClick({ key }) {
    if (key === "help") {
      window.open("https://docs.powershelluniversal.com");
      return;
    }

    if (key.startsWith("udpage")) navigate(key.substr(4));
    else navigate("/admin/" + key);
  }

  const collapsed = sessionStorage.getItem('nav-collapsed') === 'true';

  const onCollapse = (collapsed) => {
    sessionStorage.setItem('nav-collapsed', collapsed);
  }


  const pageMenu = () => {
    if (isLoading || !pages) {
      return <React.Fragment />
    }
    const getUrl = (navPage: Page) => {
      let url = navPage.url && navPage.url !== '' ? navPage.url : navPage.name;
      if (url.startsWith("/")) {
        url = url.substring(1, url.length);
      }
      return url;
    }

    const renderPageMenuItem = (navPage: Page) => {
      const url = getUrl(navPage);
      return <Menu.Item key={"udpage/" + url} icon={navPage.icon && React.createElement(Icons[navPage.icon])}> {navPage.name}</Menu.Item>
    }

    const groups = pages.filter(m => m.showInNavigation).filter(m => m.group).map(m => m.group).filter((value, index, self) => self.indexOf(value) === index).sort((a, b) => a.localeCompare(b));
    const ungroupedPages = pages.filter(m => m.showInNavigation && !m.group).sort((a, b) => a.name.localeCompare(b.name));

    let menuItems = [];
    ungroupedPages.forEach(page => {
      menuItems.push(renderPageMenuItem(page));
    });

    groups.forEach(group => {
      menuItems.push(<Menu.SubMenu key={group} title={group}>{pages.filter(m => m.group === group).map(renderPageMenuItem)}</Menu.SubMenu>)
    });

    return menuItems;
  }

  return (
    <Layout.Sider
      collapsible
      defaultCollapsed={collapsed}
      onCollapse={onCollapse}
      theme={currentTheme as SiderTheme}
    >
      <Scrollbars autoHide hideTracksWhenNotNeeded>
        <Menu
          mode="inline"
          theme={currentTheme as SiderTheme}
          key={currentTheme}
          style={{ height: "100%", borderRight: 0 }}
          onClick={onClick}
        >
          {!settings?.hideHomePage && userData?.builtInRole && (
            <Menu.Item key="home" icon={<HomeOutlined />}>
              Home
            </Menu.Item>
          )}
          {!settings?.hideApi && userData?.builtInRole && (
            <Menu.SubMenu icon={<ApiOutlined />} key="api" title="APIs">
              <Menu.Item key="apis/endpoints" icon={<ApiOutlined />}>
                Endpoints
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {!settings?.hideAutomation && (
            <Menu.SubMenu
              icon={<HistoryOutlined />}
              key="automation"
              title="Automation"
            >
              <Menu.Item key="automation/scripts" icon={<FileTextOutlined />}>
                Scripts
              </Menu.Item>
              <Menu.Item key="automation/jobs" icon={<ProfileOutlined />}>
                Jobs
              </Menu.Item>
              {/* <Menu.Item key="automation/hotkeys" icon={<ThunderboltOutlined />} hidden={userData?.mode !== 'Desktop'}>
                Hotkeys
              </Menu.Item> */}
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/schedules"
                icon={<ClockCircleOutlined />}
              >
                Schedules
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/terminals"
                icon={<CodeOutlined />}
              >
                Terminals
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="automation/triggers"
                icon={<RocketOutlined />}
              >
                Triggers
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {!settings?.hideDashboard && !userData?.builtInRole && pageMenu()}
          {!settings?.hideDashboard && userData?.builtInRole && (
            <Menu.SubMenu
              icon={<DesktopOutlined />}
              key="uis"
              title="User Interfaces"
            >
              <Menu.Item key="dashboards" icon={<LineChartOutlined />}>
                Dashboards
              </Menu.Item>
              <Menu.Item key="pages" icon={<GroupOutlined />}>
                Pages
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.builtInRole && (
            <Menu.SubMenu
              icon={<BlockOutlined />}
              key="platform"
              title="Platform"
            >
              <Menu.Item key="platform/debugging" icon={<BugOutlined />}>
                Debugging
              </Menu.Item>
              <Menu.Item key="platform/folders" icon={<FolderOutlined />}>
                Published Folders
              </Menu.Item>
              <Menu.Item key="platform/modules" icon={<ImportOutlined />}>
                Modules
              </Menu.Item>
              <Menu.Item key="platform/ratelimits" icon={<FunnelPlotOutlined />}>
                Rate Limits
              </Menu.Item>
              <Menu.Item key="platform/templates" icon={<AppstoreOutlined />}>
                Templates
              </Menu.Item>
              <Menu.Item
                hidden={!userData?.builtInRole}
                key="platform/variables"
                icon={<ControlOutlined />}
              >
                Variables
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.roles.includes("Administrator") && (
            <Menu.SubMenu
              icon={<SettingOutlined />}
              key="settingsheader"
              title="Settings"
            >
              <Menu.Item key="settings" icon={<SettingOutlined />}>
                General
              </Menu.Item>
              <Menu.Item key="settings/configurations" icon={<BuildOutlined />}>
                Configurations
              </Menu.Item>
              <Menu.Item key="settings/environments" icon={<GlobalOutlined />}>
                Environments
              </Menu.Item>
              <Menu.Item key="settings/git" icon={<PullRequestOutlined />}>
                Git
              </Menu.Item>
              <Menu.Item key="settings/license" icon={<FileProtectOutlined />}>
                License
              </Menu.Item>
              {userData?.mode !== "Desktop" && (
                <Menu.Item key="settings/loginPage" icon={<LoginOutlined />}>
                  Login Page
                </Menu.Item>
              )}

              <Menu.Item key="settings/tags" icon={<TagsOutlined />}>
                Tags
              </Menu.Item>
              <Menu.Item key="help" icon={<QuestionCircleOutlined />}>
                Help
              </Menu.Item>
            </Menu.SubMenu>
          )}
          {userData?.roles.includes("Administrator") && userData?.mode !== "Desktop" && (
            <Menu.SubMenu
              icon={<SafetyOutlined />}
              key="security"
              title="Security"
            >
              <Menu.Item
                key="security/authentication"
                icon={<SecurityScanOutlined />}
              >
                Authentication
              </Menu.Item>
              <Menu.Item key="security/identities" icon={<IdcardOutlined />}>
                Identities
              </Menu.Item>
              <Menu.Item key="security/roles" icon={<TeamOutlined />}>
                Roles
              </Menu.Item>
              <Menu.Item key="security/tokens" icon={<BarcodeOutlined />}>
                Tokens
              </Menu.Item>

            </Menu.SubMenu>
          )}
        </Menu>
      </Scrollbars>
    </Layout.Sider>
  );
}
