import { Script } from "../../../types";
import { CustomSchema } from "./types";

export const scriptSchame: CustomSchema<Script>[] = [
  {
    component: "input",
    label: "Name",
    name: "name",
    tooltip: "Name of the script as shown in Universal Automation. This will also be the name used to persist the script to disk.",
    required: true,
    hasFeedback: true,
    initialValue: "",
  },
  {
    name: "description",
    component: "input",
    label: "Description",
    initialValue: "",
  },
  {
    name: "timeOut",
    component: "inputNumber",
    label: "Time Out (Minutes)",
    initialValue: 0,
    tooltip: "The time out value in minutes. 0 means no time out.",
    hideInCreateMode: true,
  },
  {
    name: "retryLimit",
    component: "inputNumber",
    label: "Retry Limit",
    initialValue: 0,
    tooltip: "The number of times to retry the script if it fails or times out.",
    hideInCreateMode: true,
  },
  {
    name: "maxHistory",
    component: "inputNumber",
    label: "Max History",
    initialValue: 100,
    tooltip: "The maximum number of jobs to keep for this script. The oldest jobs will be removed first.",
    hideInCreateMode: true,
  },
  {
    name: "anonymous",
    component: "checkbox",
    label: "Anonymous",
    initialValue: false,
    valuePropName: "checked",
    tooltip: "Whether this script can be invoked without the user being authenticated.",
    hideInCreateMode: true,
  },
  {
    name: "discardPipeline",
    component: "checkbox",
    label: "Discard Pipeline",
    initialValue: false,
    valuePropName: "checked",
    tooltip: "Whether to discard pipeline output. This will greatly improve performance.",
    hideInCreateMode: true,
  },
  {
    component: "errorAction",
    name: "errorAction",
    label: "Error Action",
    initialValue: "SilentlyContinue",
    tooltip: "The error action to take when the script throws an error. If set to Stop, the job will fail due to any errors within the script.",
  },
  {
    name: "environment",
    label: "Environment",
    component: "environment",
    initialValue: null,
    tooltip: "The environment to run the script in. If not specified, the script will be run in the default environment.",
  },
  {
    name: "credential",
    label: "Credential",
    component: "credential",
    initialValue: null,
    tooltip: "The credential to use when running the script. If not specified, the script will be run with the default credential.",
  },
  {
    name: "tag",
    label: "Tags",
    component: "tag",
    initialValue: [],
    hideInCreateMode: true,
  },
  {
    component: "checkbox",
    name: "disableManualInvocation",
    label: "Disable Manual Invocation",
    valuePropName: "checked",
    initialValue: false,
    tooltip: "If checked, users will not be able to execute the script manually. It can only be scheduled.",
    hideInCreateMode: true,
  },
  {
    component: "checkbox",
    name: "gotoScript",
    valuePropName: "checked",
    label: "Go to script page",
    initialValue: true,
    hideInEditMode: true,
  },
];
