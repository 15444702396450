import { PlusOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Space, Modal, Tabs, Typography } from "antd";
import React, { ReactNode } from "react";

const { TabPane } = Tabs;

export default function Toolbox(props) {

    const renderItem = (item): ReactNode => {
        return (
            <Button key={item} icon={<PlusOutlined />} onClick={() => props.addComponent(item)}>{item}</Button>
        )
    }

    return (
        <Modal
            title={<><ToolOutlined />   Toolbox</>}
            onOk={props.onClose}
            onCancel={props.onClose}
            visible={props.visible}
            width={"90vw"}
        >
            <Tabs defaultActiveKey="1">
                <TabPane tab="Charts" key="1">
                    <Space direction="vertical">
                        <Typography>Display charts based on APIs and Script output.</Typography>
                        <Space>
                            {["Bar", "Line", "Liquid", "Pie"].map(renderItem)}
                        </Space>
                    </Space>
                </TabPane>
                <TabPane tab="Data Display" key="2">
                    <Space direction="vertical">
                        <Typography>Static and dynamic data displays.</Typography>
                        <Space>
                            {["Alert", "IFrame", "Image", "Statistic", "Table", "Tag"].map(renderItem)}
                        </Space>
                    </Space>
                </TabPane>
                <TabPane tab="Data Input" key="3">
                    <Space direction="vertical">
                        <Typography>Execute APIs and scripts based on user input.</Typography>
                        <Space>
                            {["Button", "Form"].map(renderItem)}
                        </Space>
                    </Space>
                </TabPane>
                <TabPane tab="Typography" key="4">
                    <Space direction="vertical">
                        <Typography>Static text to display on the page.</Typography>
                        <Space>
                            {["Paragraph", "Text", "Title"].map(renderItem)}
                        </Space>
                    </Space>
                </TabPane>
            </Tabs>
        </Modal>
    )
}