import React, { lazy } from "react";
import { Button, Result } from "antd";
const UnAuthorizedImage = lazy(
  () =>
    import(
      /* webpackChunkName: 'UnauthorizedImage' */ "./unauthorized-image.js"
    )
);

export default function UnAuthorizedPage() {
  return (
    <Result
      title="Unauthorized Access"
      subTitle="You are not authorized to view this page."
      icon={<UnAuthorizedImage />}
      extra={[
        <Button type="primary" onClick={() => window.location.reload()}>
          Login
        </Button>
      ]
      }
    />
  );
}
