import React from "react";
import { AppContext } from "./Context";
import { Update, Stats, Accessible, Settings, Feature, Sso } from "../../../types";
import { useQuery } from "react-query3";


export default function AppProvider({ children }) {

  const { data: sso, isLoading: ssoLoading } = useQuery<Sso>("/sso", { staleTime: Infinity, cacheTime: Infinity });
  const { data: userData, isLoading } = useQuery<Accessible>("/accessible", { staleTime: Infinity, cacheTime: Infinity, retry: false });

  const isEnabled = userData?.builtInRole === true;

  const { data: stats } = useQuery<Stats>("/stats", {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: isEnabled
  });
  const { data: version } = useQuery<string>("/version", { staleTime: Infinity, cacheTime: Infinity });
  const { data: update } = useQuery<Update>("/version/update", { staleTime: Infinity, cacheTime: Infinity });
  const { data: settings } = useQuery<Settings[]>("/settings", { enabled: isEnabled });
  const { data: features } = useQuery<Feature[]>("/license/feature", { staleTime: Infinity, cacheTime: Infinity });

  const licenseCheck = (feature: Feature) => features && features.indexOf(feature) !== -1;

  return (
    <AppContext.Provider
      value={{
        stats,
        version,
        update,
        userData,
        //@ts-ignore
        settings,
        sso,
        licensed: licenseCheck,
        licensedFeatures: features,
        isUserDataLoading: isLoading,
        isSsoDataLoading: ssoLoading
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
