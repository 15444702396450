import { Select } from "antd";
import React from "react";
import { Schedule, Script } from "../../../types";
import queryClient from "../../utils/queryClient";
import { Field } from "./Form";
import { CustomSchema } from "./types";

function setComponentByParameterType(parameter) {
  return parameter.displayType === 0
    ? "input"
    : parameter.displayType === 1
      ? "inputNumber"
      : parameter.displayType === 2
        ? "switch"
        : parameter.displayType === 3
          ? "datetimePicker"
          : "credential";
}

export const scheduleSchema: CustomSchema<Schedule>[] = [
  {
    name: "type",
    label: "Type",
    component: "typeSelector",
    required: true,
    uniqe: false,
    initialValue: "simple",
  },
  {
    name: "script",
    label: "Script",
    tooltip: "Select the script to create schedule for it.",
    component: "scriptSelect",
    required: true,
    uniqe: false
  },
  {
    name: "name",
    label: "Name",
    tooltip: 'Schedule name',
    component: "input"
  },
  {
    name: "timeout",
    component: "inputNumber",
    label: "Time Out (Minutes)",
    initialValue: 0,
    tooltip: "The time out value in minutes. 0 means no time out.",
  },
  {
    name: "paused",
    label: "Paused",
    tooltip: "This schedule will not start jobs.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    name: "randomDelay",
    label: "Random Delay",
    tooltip: "Randomly delays the schedule start time from 0 to 60 seconds.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    name: "cron",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "environment",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "credential",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "delay",
    hidden: true,
    style: { display: "none" },
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      let selectedType = getFieldValue("type");
      let cron = getFieldValue("cron");
      //let delay = getFieldValue("delay");

      return selectedType === "simple" ? (
        <Field
          component="simpleTime"
          name="simple"
          label="Time"
          tooltip="Schedules a script to run based on a simple schedule."
          preserve={false}
          initialValue={cron || "* * * * *"}
        />
      ) : selectedType === "continuous" ? (
        <Field
          component="inputNumber"
          name="delay"
          label="Delay"
          tooltip="Schedules a script to run continuously with a delay in between each run. in seconds"
          preserve={false}
        //initialValue={delay || 60}
        />
      ) : selectedType === "cron" ? (
        <Field
          component="input"
          name="cron"
          label="Expression"
          tooltip="Schedules a script to run based on a CRON expression"
          preserve={false}
        //initialValue={cron || "* * * * *"}
        />
      ) : (
        <Field
          component="datetimePicker"
          name="datetime"
          label="Date"
          tooltip="Schedules a script to run one time."
          preserve={false}
        />
      );
    },
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");
      let environment = getFieldValue("environment");

      let script = queryClient
        .getQueryData<Script[]>("/script")
        ?.find((script) => script.fullPath === selectedScript);
      return script?.environment === null ? (
        <Field
          component="environment"
          name="environment"
          label="Environment"
          initialValue={environment || "Default"}
          preserve={false}
        />
      ) : null;
    },
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");
      let script = queryClient
        .getQueryData<Script[]>("/script")
        ?.find((script) => script.fullPath === selectedScript);
      return script?.credential === null ? (
        <Field
          component="credential"
          name="credential"
          label="Credential"
          initialValue="Default"
          preserve={false}
        />
      ) : null;
    },
  },
  {
    required: false,
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");
      let script = queryClient
        .getQueryData<Script[]>("/script")
        ?.find((script) => script.fullPath === selectedScript);
      return script?.scriptParameters?.length > 0
        ? script?.scriptParameters?.map((parameter) => {
          return parameter.displayType === 4 ? (
            <Field
              key={parameter.id}
              name={parameter.name}
              label={parameter.name}
              required={parameter.required}
              initialValue={parameter.defaultValue}
              tooltip={parameter.helpText}
              preserve={false}
            >
              <Select>
                {parameter.validValues?.map((validValue) => {
                  return (
                    <Select.Option key={validValue} value={validValue}>
                      {validValue}
                    </Select.Option>
                  );
                })}
              </Select>
            </Field>
          ) : parameter.displayType === 6 ? (
            <Field
              key={parameter.id}
              name={parameter.name}
              label={parameter.name}
              required={parameter.required}
              initialValue={parameter.defaultValue}
              tooltip={parameter.helpText}
              preserve={false}
            >
              <Select mode="tags" tokenSeparators={[",", ";"]} />
            </Field>
          ) : (
            <Field
              key={parameter.id}
              name={parameter.name}
              label={parameter.name}
              required={parameter.required}
              initialValue={parameter.defaultValue}
              tooltip={parameter.helpText}
              component={setComponentByParameterType(parameter)}
              preserve={false}
            />
          );
        })
        : null;
    },
  },
];
