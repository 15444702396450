import { Terminal } from "../../../types";
import { CustomSchema } from "./types";

export const terminalSchema: CustomSchema<Terminal>[] = [
    {
        component: "input",
        label: "Name",
        name: "name",
        required: true,
    },
    {
        component: "input",
        label: "Description",
        name: "description",
    },
    {
        name: "environment",
        label: "Environment",
        component: "environment",
        initialValue: null,
        tooltip: "The environment to run the script in. If not specified, the script will be run in the default environment.",
    },
    {
        name: "credential",
        label: "Credential",
        component: "credential",
        initialValue: null,
        tooltip: "The credential to use when running the script. If not specified, the script will be run with the default credential.",
    }
]