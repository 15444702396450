import React from "react";
import { useQuery } from "react-query3";
import { Button, PageHeader, Space } from "antd";
import { Modal } from "../ui/Modal";
import { AppToken, CustomColumns } from "../../types";
import Table from "../ui/Table";
import DeleteButton from "../ui/DeleteButton";
import CopyButton from "../ui/CopyButton";
import NewAppTokenModal from "./NewAppTokenModal";
import { formatDate } from "components/utils/utils";
import { LinkOutlined } from "@ant-design/icons";



export default function Tokens() {
  const { data: tokens, isLoading, refetch } = useQuery<AppToken[]>(
    "/apptoken",
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  function setHidden(record: AppToken) {
    return record?.revoked;
  }

  const columns: CustomColumns<AppToken>[] = [
    {
      title: "Identity",
      dataIndex: ["identity", "name"],
      key: "identity",
      editable: false,
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      editable: false,
      render: (text, record) => formatDate(text),
    },
    {
      title: "Expiration",
      dataIndex: "expiration",
      editable: false,
      key: "expiration",
      render: (text, record) => formatDate(text),
    },
    {
      title: "Revocation Date",
      dataIndex: "revocation Date",
      editable: false,
      key: "revocation",
      render: (text, record) => record?.revoked && formatDate(text),
    },
    {
      title: "Role",
      dataIndex: "role",
      editable: false,
      key: "role",
    },
    {
      title: "Revoked",
      dataIndex: "revoked",
      editable: false,
      key: "revoked",
      render: (text, record) => (record.revoked ? "Yes" : "No"),
    },
    {
      width: "fit-content",
      dataIndex: "actions",
      editable: false,
      render: (text, record) => {
        return (
          <Space>
            <CopyButton hidden={setHidden(record)} text={record.token} allowedWithOneWayGitSync={true} />
            <DeleteButton hidden={setHidden(record)} resource={record} allowedWithOneWayGitSync={true} />
          </Space>
        );
      },
    },
  ];

  return (
    <PageHeader title="Application Tokens" subTitle="Application tokens for invoking the PowerShell Universal API." extra={[
      <Button icon={<LinkOutlined />} href="/swagger" target="_blank">API Documentation</Button>,
      <Button icon={<LinkOutlined />} href="https://docs.powershelluniversal.com/config/security/app-tokens" target="_blank">Documentation</Button>
    ]}>
      <Table
        title="Tokens"
        actions={[
          <Modal>
            <NewAppTokenModal />
          </Modal>,
        ]}
        data={tokens}
        columns={columns}
        loading={isLoading}
        queryFn={refetch}
      />
    </PageHeader >
  );
}
