import React from "react";
import { Input as AntInput } from 'antd';
const Input = React.lazy(() => import("antd/es/input"));
const Password = React.lazy(() => import("antd/es/input/Password"));
const Checkbox = React.lazy(() => import("antd/es/checkbox"));
const Select = React.lazy(() => import("antd/es/select"));
const Switch = React.lazy(() => import("antd/es/switch"));
const InputNumber = React.lazy(() => import("antd/es/input-number"));
const ErrorActionSelectBox = React.lazy(() => import("../../ui/ErrorActionSelectBox"));
const TagSelectBox = React.lazy(() => import("../../ui/SelectTagBox"));
const EnvironmentSelectBox = React.lazy(() => import("../../ui/EnvironmentSelectBox"));
const VariableSelectBox = React.lazy(() => import("../../ui/VariableSelectBox"));
const ColorPicker = React.lazy(() => import("../../ui/ColorPicker"));
const TagPreview = React.lazy(() => import("../../ui/TagPreview"));
const EventTypeSelectBox = React.lazy(() => import("../../ui/EventTypeSelectBox"));
const RoleSelectBox = React.lazy(() => import("../../ui/RoleSelectBox"));
const RoleTagSelectBox = React.lazy(() => import("../../ui/RoleTagSelectBox"));
const ExpirationTimeSelectBox = React.lazy(() => import("../../ui/ExpirationTimeSelectBox"));
const ScheduleTypeSelector = React.lazy(() => import("../../ui/ScheduleTypeSelector"));
const SimpleTimeSelectBox = React.lazy(() => import("../../ui/SimpleTimeSelectBox"));
const DateTimePicker = React.lazy(() => import("../../ui/DateTimePicker"));
const CredentialsSelectBox = React.lazy(() => import("../../ui/CredentialsSelectBox"));
const SecretTypeSelector = React.lazy(() => import("../../ui/SecretTypeSelector"));
const VariableTypeSelector = React.lazy(() => import("../../ui/VariableTypeSelector"));
const VaultSelectBox = React.lazy(() => import("../../ui/VaultSelectBox"));
const MethodSelectBox = React.lazy(() => import("../../ui/methodSelectBox"));
const AuthSwitch = React.lazy(() => import("../../ui/authSwitch"));
const PeriodSelectBox = React.lazy(() => import("../../ui/PeriodSelectBox"));
const SelectAsTags = React.lazy(() => import("../../ui/SelectAsTags"));
const FrameworkSelectBox = React.lazy(() => import("../../ui/FrameworkSelectBox"));
const UploadFile = React.lazy(() => import("../../ui/Upload"));
const ScriptSelect = React.lazy(() => import("../../ui/ScriptSelect"));
const KeySelect = React.lazy(() => import("../../ui/keySelect"));
const ModifierKeySelect = React.lazy(() => import("../../ui/modifierKeySelect"));
const VariableTypeSelect = React.lazy(() => import("../../ui/VariableTypeSelect"));

// mapping of our components
export default function getComponent(component: string) {
  switch (component) {
    case "input":
      return Input;
    case "textarea":
      return AntInput.TextArea;
    case "inputNumber":
      return InputNumber;
    case "password":
      return Password;
    case "checkbox":
      return Checkbox;
    case "switch":
      return Switch;
    case "select":
      return Select;
    case "scriptSelect":
      return ScriptSelect;
    case "selectTag":
      return (props) => <Select {...props} mode="tags" />;
    case "colorPicker":
      return ColorPicker;
    case "tag":
      return TagSelectBox;
    case "errorAction":
      return ErrorActionSelectBox;
    case "environment":
      return EnvironmentSelectBox;
    case "variables":
      return VariableSelectBox;
    case "preview":
      return TagPreview;
    case "eventType":
      return EventTypeSelectBox;
    case "role":
      return RoleSelectBox;
    case "roleTags":
      return RoleTagSelectBox;
    case "expirationTime":
      return ExpirationTimeSelectBox;
    case "typeSelector":
      return ScheduleTypeSelector;
    case "simpleTime":
      return SimpleTimeSelectBox;
    case "datetimePicker":
      return DateTimePicker;
    case "credential":
      return CredentialsSelectBox;
    case "secretTypeSelector":
      return SecretTypeSelector;
    case "variableTypeSelector":
      return VariableTypeSelector;
    case "vault":
      return VaultSelectBox;
    case "methodSelector":
      return MethodSelectBox;
    case "authSwitch":
      return AuthSwitch;
    case "period":
      return PeriodSelectBox;
    case "selectAsTags":
      return SelectAsTags;
    case "frameworkSelectBox":
      return FrameworkSelectBox;
    case "upload":
      return UploadFile;
    case "key":
      return KeySelect;
    case "modifierKey":
      return ModifierKeySelect;
    case "variableTypeSelect":
      return VariableTypeSelect;
    default:
      throw new Error(
        `component need to be one of the following : 
          input,
          inputNumber, 
          password, 
          checkbox, 
          switch, 
          select,
          colorPicker, 
          tag, 
          errorAction, 
          environment,
          variables,
          preview,
          eventType`
      );
  }
}
