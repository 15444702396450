import { Role } from "../../../types";
import { CustomSchema } from "./types";

export const roleSchema: CustomSchema<Role>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Name can't be empty."
    }]
  },
  {
    name: "description",
    label: "Description",
    component: "input",
    required: false,
  },
  {
    name: "claimType",
    label: "Claim Type",
    tooltip: "The claim type that will be used to identify the role. The policy script will be ignored if this is set.",
    component: "input",
    required: false,
  },
  {
    name: "claimValue",
    label: "Claim Value",
    tooltip: "The claim value that will be used to identify the role. The policy script will be ignored if this is set.",
    component: "input",
    required: false,
  },
];
