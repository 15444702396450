import React, { useState } from "react";
import { Menu, Layout, Space, Typography, Button, Dropdown, Tag, Tooltip } from "antd";
import { UserOutlined, LogoutOutlined, BarcodeOutlined, CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useQuery } from "react-query3";
import Logo from "../ui/Logo";
import useAppStore from "../context/app/Hooks";
import queryClient from "components/utils/queryClient";
import ThemeToggle from "components/ui/theme/themeToggle";
import Notification from "components/ui/Notification";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { useNavigate } from "react-router";
import RoleGuard from "components/standalone/role-guard";
import { Feature, LoginPage } from "types";
import { Link } from "react-router-dom";

const UAMenuHeader = () => {
  const { userData, version, licensed } = useAppStore();
  const navigate = useNavigate();
  const [templateClosed, setTemplateClosed] = useState(localStorage.getItem("template-closed") === "true")
  const { data: authType } = useQuery<string>("/authmethod");
  const { data: loginPage } = useQuery<LoginPage>("/loginPage");
  const { refetch: logoff } = useQuery<string>("/signout", {
    enabled: false,
    onSuccess: () => queryClient.clear(),
  });

  const closeTemplate = () => {
    localStorage.setItem("template-closed", "true");
    setTemplateClosed(true);
  }

  const { currentTheme } = useThemeSwitcher()
  const onClick = (key) => {
    if (key === "signout") {
      logoff().then(() => (window.location.href = "/login"))
    }

    if (key === "tokens") {
      navigate("/admin/security/tokens")
    }
  };

  return (
    <Layout.Header

      style={{
        color: currentTheme === 'light' ? "#000000" : "#ffffff",
        backgroundColor: currentTheme === 'light' ? '#fff' : '#1f1f1f',
        padding: "0px 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Logo mode={userData?.mode} />
      <Space>
        {loginPage?.defaultAuthentication && !templateClosed && <>
          <RoleGuard requiredRoles={["Administrator"]}>
            <Typography.Text
              style={{ verticalAlign: "sub", marginRight: 24 }}
            >
              <Link to="/admin/platform/templates">
                <Tag title="Licensed" color="blue" icon={<CheckCircleOutlined />} closable={true} onClose={closeTemplate}>Don't know where to start? Import a template.</Tag>
              </Link>
            </Typography.Text>
          </RoleGuard>
        </>}

        <RoleGuard requiredRoles={["Administrator"]}>
          <Typography.Text
            style={{ verticalAlign: "sub", marginRight: 24 }}
          >
            <Link to="/admin/settings/license">
              {licensed(Feature.Automation) ?
                <Tag title="Licensed" color="success" icon={<CheckCircleOutlined />}>Licensed</Tag> :
                <Tooltip title="Some features of PowerShell Universal are not available."><Tag title="Unlicensed" color="error" icon={<CloseCircleOutlined />}>Unlicensed</Tag></Tooltip>}
            </Link>
          </Typography.Text>
        </RoleGuard>
        <Typography.Text
          style={{ verticalAlign: "sub", marginRight: 24 }}
        >
          {version}
        </Typography.Text>
        <ThemeToggle
          checkedChildren="Dark"
          unCheckedChildren="Light"
          size="small"
        />
        <Notification />
        <Dropdown
          placement="bottomCenter"
          //@ts-ignore
          destroyPopupOnHide={true}
          trigger={["click"]}
          overlay={() => (
            <Menu onClick={({ key }) => onClick(key)}>
              {(userData?.roles.includes("Administrator") || userData?.roles.includes("Operator") || userData?.roles.includes("Execute")) && (
                <Menu.Item key="tokens" icon={<BarcodeOutlined />}>
                  Tokens
                </Menu.Item>
              )}
              {authType === "Cookies" && (
                <Menu.Item key="signout" icon={<LogoutOutlined />}>
                  Sign Out
                </Menu.Item>
              )}
            </Menu>
          )}
        >
          <Button type="text" icon={<UserOutlined />}>{userData?.username}</Button>
        </Dropdown>
      </Space>
    </Layout.Header>
  );
};

export default UAMenuHeader;
