import React from "react";
import { Form, Select } from "antd";
import { useQuery } from "react-query3";
import { Endpoint } from "./../../../types";
import { VariableContext } from "../VariableContext";

export default function Api(props) {
    const { data: apis, isLoading: apisLoading } = useQuery<Endpoint[]>("/endpoint");
    const [searchText, setSearchText] = React.useState("");

    return (
        <VariableContext.Consumer>
            {(context) => (
                <>
                    <Form.Item
                        label={props.displayName}
                        name={props.name}
                        rules={[{ required: props.required }]}
                        tooltip={props.description}
                    >
                        <Select loading={apisLoading} onSearch={(val) => setSearchText(val)} showSearch>
                            {searchText !== '' && <Select.Option key="search" value={searchText}>{searchText}</Select.Option>}
                            {apis?.filter(x => x.method === 'POST').map((endpoint) => (
                                <Select.Option key={endpoint.id} value={endpoint.url}>{`${endpoint.url}`}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </>
            )}
        </VariableContext.Consumer>
    );
}
