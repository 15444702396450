import React from "react";
import { Typography } from 'antd';
import { Route, Routes } from "react-router-dom";
import { Page as PSUPage } from 'types';
import Page from './Page'
import { useQuery } from "react-query3";
import queryClient from "components/utils/queryClient";
import useAppStore from "../context/app/Hooks";


export default function PageDashboard() {
    const { data: pages, isLoading } = useQuery<PSUPage[]>("/page/view");
    const { refetch: logoff } = useQuery<string>("/signout", {
        enabled: false,
        onSuccess: () => queryClient.clear(),
    });
    const { userData, licensedFeatures } = useAppStore();

    const onLogout = () => {
        logoff().then(() => (window.location.href = "/login"))
    };

    if (isLoading || !pages) {
        return <Typography>Loading...</Typography>
    }

    const pageRoutes = pages.map(page => {
        let url = page.url && page.url !== '' ? page.url : page.name;
        if (url.startsWith('/')) {
            url = url.substr(1);
        }

        return <Route key={"route" + page.name} path={url} element={
            <Page
                page={page}
                pages={pages}
                logout={onLogout}
                username={userData?.username}
                mode={userData?.mode}
                builtInRole={userData?.builtInRole === true}
                licensed={licensedFeatures?.length > 0} />
        } />
    })

    return <Routes>{pageRoutes}</Routes>
}