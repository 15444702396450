import { FC, useState } from 'react'
import { Button, Tooltip, Space } from 'antd'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { EditorContext } from './EditorContext'
import ComponentPropertiesModal from './ComponentProperties'

export interface CardProps {
  component: any;
}

export const DesignerCard: FC<CardProps> = ({ children, component }) => {
  const [propsVisible, setPropsVisible] = useState(false);

  const setVisibility = (visible: boolean, lockDesigner) => {
    setPropsVisible(visible);
    lockDesigner(visible);
  }

  return (
    <div style={{ height: "100%", border: "1px dashed" }}>
      <EditorContext.Consumer>
        {({ removeComponent, setComponent, lockDesigner }) => (
          <>
            <div style={{ position: "absolute", height: 24, top: 5, right: 10, marginLeft: 'auto', zIndex: 2 }}>
              <Space>
                <Tooltip title="Properties">
                  <Button type="primary" shape="circle" icon={<EditOutlined />} onClick={() => setVisibility(true, lockDesigner)} size="small" />
                </Tooltip>
                <Tooltip title="Delete">
                  <Button shape="circle" icon={<DeleteOutlined />} onClick={() => removeComponent(component)} size="small" />
                </Tooltip>
              </Space>
            </div>
            <div style={{ height: "100%", zIndex: 1 }}>
              {children}
            </div>
            <ComponentPropertiesModal
              visible={propsVisible}
              setVisible={(visible) => setVisibility(visible, lockDesigner)}
              component={component}
              setComponent={setComponent}
            />
          </>
        )}
      </EditorContext.Consumer>
    </div>
  )
}
