import { useContext, useEffect } from "react";
import { useQuery } from "react-query3";
import { JobQuery } from "../../types";
import { VariableContext } from "./VariableContext";

export default function useDataSource({
    id,
    refreshInterval,
    dataSourceType,
    dataSource,
    onSuccess,
    onError,
}: {
    id: string,
    refreshInterval?: number,
    dataSourceType: string,
    dataSource: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
}) {

    const { registerComponent, unregisterComponent } = useContext(VariableContext);
    const enabled = !!dataSource && !!dataSourceType;

    const { data: jobs, isLoading: jobsIsLoading, refetch: jobRefetch } = useQuery<JobQuery>(`script/${dataSource}/job?status=completed&orderBy=id&orderDirection=descending&skip=0&take=1`, {
        refetchInterval: refreshInterval === undefined || !refreshInterval || refreshInterval === 0 ? false : refreshInterval * 1000,
        queryFn: async () => {
            const response = await fetch(`/api/v1/script/job/${dataSource}?status=completed&orderBy=id&orderDirection=descending&skip=0&take=1`);
            const data = await response.json();
            return data;
        },
        onSuccess: onSuccess,
        onError,
        enabled: enabled && dataSourceType === "script"
    });
    const jobId = jobs?.page[0].id;
    const { data: pipelineOutput, isLoading: pipelineOutputIsLoading } = useQuery<Array<any>>(`job/${jobId}/pipelineOutput`, {
        refetchInterval: refreshInterval === undefined || !refreshInterval || refreshInterval === 0 ? false : refreshInterval * 1000,
        queryFn: async () => {
            const response = await fetch(`/api/v1/job/${jobId}/pipelineOutput`);
            const data = await response.json();
            return JSON.parse(data[0].jsonData).map(obj => {
                var newObj = {};
                obj.children.forEach(child => { newObj[child.name] = String(child.value); });
                return newObj;
            });
        },
        onSuccess,
        onError,
        enabled: enabled && !!jobId
    });

    const { data: apiData, isLoading: apiDataIsLoading, refetch: apiRefetch } = useQuery<Array<any>>(dataSource, {
        refetchInterval: refreshInterval === undefined || !refreshInterval || refreshInterval === 0 ? false : refreshInterval * 1000,
        queryFn: async () => {
            const response = await fetch(dataSource);
            const data = await response.json();
            return data;
        },
        onSuccess,
        onError,
        enabled: enabled && dataSourceType === "api"
    });

    var data = [];
    if (dataSourceType === "api") {
        data = apiData;
    }

    if (dataSourceType === "script") {
        data = pipelineOutput;
    }

    useEffect(() => {
        registerComponent(id, dataSourceType === "api" ? dataSource : "script/job/" + dataSource);
        return () => unregisterComponent(id);
    }, [id, dataSource, dataSourceType, registerComponent, unregisterComponent]);

    return {
        data,
        isLoading: apiDataIsLoading || pipelineOutputIsLoading || jobsIsLoading,
        refetch: () => apiRefetch() || jobRefetch()
    }
}

export const loadDataSource = async (dataSource: string, dataSourceType: string) => {
    if (dataSourceType === 'api') {
        const response = await fetch(dataSource);
        const data = await response.json();
        return data;
    }

    if (dataSourceType === 'script') {
        const response = await fetch(`script/${dataSource}/job?status=completed&orderBy=id&orderDirection=descending&skip=0&take=1`);
        const jobs = await response.json();

        if (!jobs || !jobs.page || !jobs.page[0]) {
            return null;
        }

        const jobId = jobs.page[0].id;

        const response2 = await fetch(`job/${jobId}/pipelineOutput`);
        const pipelineOutput = await response2.json();
        return JSON.parse(pipelineOutput[0].jsonData).map(obj => {
            var newObj = {};
            obj.children.forEach(child => { newObj[child.name] = String(child.value); });
            return newObj;
        });
    }

    return null;
}