import { Environment } from "../../../types";
import { CustomSchema } from "./types";

export const environmentSchame: CustomSchema<Environment>[] = [
  {
    name: "name",
    label: "Name",
    component: "input",
    required: true,
    rules: [{
      required: true,
      message: "Environment name can't be empty."
    }],
    tooltip: "Name of the environment. This is how the environment will be shown and referenced through out the platform."
  },
  {
    name: "path",
    label: "Path",
    component: "input",
    required: true,
    tooltip: "The path to the PowerShell executable. Ignored for the Integrated environment."
  },
  {
    name: "arguments",
    label: "Arguments",
    component: "input",
    required: false,
    tooltip: "Arguments to pass to the PowerShell process for this environment. Ignored for the Integrated environment."
  },
  {
    name: "variables",
    label: "Variables",
    component: "variables",
    required: false,
    tooltip: "Variables to make available in this environment. A * indicates all variables. You can also use * in variable names like 'PROD_*'",
    initialValue: ["*"]
  },
  {
    name: "modules",
    label: "Modules",
    component: "selectTag",
    required: false,
    tooltip: "Modules to load into this environment."
  },
  {
    name: "startupScript",
    label: "Startup Scripts",
    component: "selectTag",
    required: false,
    tooltip: "Scripts to execute when creating runspaces in this environment."
  },
  {
    name: "persistentRunspace",
    label: "Persistent Runspace",
    component: "switch",
    valuePropName: "checked",
    tooltip: "Persistent runspaces prevent the environment from resetting the runspace state on every execution",
    required: false,
  },
  {
    name: "highPerformanceRunspacePool",
    label: "High Performance",
    component: "switch",
    valuePropName: "checked",
    tooltip: "High performance runspaces pools are much faster than traditional ones but do not offer persistence or runspace recycling.",
    required: false,
  },
  {
    name: "maxRunspaces",
    label: "Max Runspaces",
    component: "inputNumber",
    tooltip: "The maximum number of runspaces to allocate to a runspace pool.",
    required: false,
  },
];
